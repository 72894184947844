import * as React from 'react'
import { heading, externalLink } from '../components/layout.module.css'

const data = [
  {
    link: "https://youtu.be/ZBiHzjj-yBE",
    title: "Kabir & the Seeker",
    description: ""
  },
  {
    link: "https://youtu.be/fz5xS4v3-ho",
    title: "Lila Downs on Identity",
    description: ""
  },
  {
    link: "https://en.wikipedia.org/wiki/Cultural_hegemony",
    title: "Cultural hegemony",
    description: ""
  },
  {
    link: "https://lithub.com/what-does-immersing-yourself-in-a-book-do-to-your-brain/",
    title: "Reading & Empathy",
    description: ""
  },
  {
    link: "https://en.wikiquote.org/wiki/Zhuangzi#:~:text=Now%20I%20do%20not%20know,from%20morning%20to%20night...",
    title: "Zhuangzhi's Butterfly Dream",
    description: ""
  },
  {
    link: "https://www.youtube.com/watch?v=MASBIB7zPo4",
    title: "What is consciousness?",
    description: ""
  },
  {
    link: "http://ww3.haverford.edu/psychology/ddavis/p109g/proust.html",
    title: "Proust's madeleine",
    description: ""
  },
  {
    link: "https://www.youtube.com/watch?v=QwXAqIaUahI",
    title: "Bill Evans on Creativity & Self Teaching",
    description: ""
  },
  {
    link: "https://faculty.washington.edu/smcohen/320/cave.htm",
    title: "Plato's Cave Allegory",
    description: ""
  },
];

const LinkItem = ({ to, title, description }) => {
  return (
    <li style={{marginBottom:"6px"}}>
      <a href={to} target="_blank" rel="noreferrer" className={externalLink}>{title}</a>
      {(description!=="") ? <p style={{marginTop:0,marginBottom:0,fontStyle:"italic"}}>{description}</p> : <></>}
    </li>
  )
}

const Knowledge = () => {
  return (
    <div>
      <title>Links &ndash; Evan Gedrich</title>
      <h1 className={heading}>Home Page Links</h1>
      <p>The following links, hidden throughout this website, are the building blocks for much of my way of seeing the world.
      This linked material is meant to provide a variety of approachable entrypoints, by way of historical characters
      and well-worn thought experiments in words and images, into an interconnected dialogue of ideas that has significantly
      impacted my experience of the world. Perhaps something here might pique your interest as it did mine.</p>
      <ul>
      {data.map(item => (
        <LinkItem to={item.link} title={item.title} description={item.description} key={item.title}></LinkItem>
      ))}
      </ul>
      <p>If this list proves too cumbersome, I'd simply suggest you direct your attention toward any media
      produced outside the mainstream "Western" tradition (e.g. ancient Mixtec or medieval Japanese poetry maybe!), as perspectives apart
      from the dominant white European worldview are the fastest route to a broader mind.</p>
      <p>Too many items from this list, begun in 2018, remain overly informed by U.S. ruling class ideals, rooted
      in a Eurocentric / Hellenistic idealogical supremacy.
      An overhaul is due, but hopefully the tools provided by even the white male writers included can
      be useful in interrogating the shortcomings of their own ideas.</p>
      <p style={{textAlign:"center"}}><br/>&bull; &nbsp; &bull; &nbsp; &bull;</p>
    </div>
  )
}

export default Knowledge
